<template>
  <div class="auth-form-wrap">
    <div class="auth-form"
         v-bind:class="formClass">
      <data-loader v-bind:isLoading="isSubmitStatusPending"
                   v-bind:isSpinner="true" />
      <h2>Авторизация</h2>
      <form v-on:submit.prevent="submitForm" novalidate>
        <div class="alert alert-danger"
             role="alert"
             v-if="isSubmitStatusError">
          Проверьте, что ввели верные логин и пароль
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <svg-icon name="email"></svg-icon>
            </div>
          </div>
          <input type="email"
                 class="form-control"
                 placeholder="E-mail"
                 v-model.trim="$v.email.$model"
                 v-bind:disabled="isSubmitStatusSuccess"
                 v-bind:class="{'is-invalid': $v.email.$error}"/>
        </div>
        <div class="input-group mb-4">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <svg-icon name="lock"></svg-icon>
            </div>
          </div>
          <input type="password"
                 class="form-control"
                 placeholder="Пароль"
                 v-model.trim="$v.password.$model"
                 v-bind:disabled="isSubmitStatusSuccess"
                 v-bind:class="{'is-invalid': $v.password.$error}" />
        </div>
        <button type="submit"
                class="btn btn-primary btn-lg"
                v-bind:disabled="this.$v.$invalid || isSubmitStatusSuccess">
          Вход
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { required, email, minLength } from 'vuelidate/lib/validators';
import DataLoader from '@/components/DataLoader.vue';
import auth from '@/lib/Auth.js';
import appContext from '@/AppInit';

const QUERY_MUTATION = gql`
  mutation($email: String!, $password: String!) {
    userLogin(
      email: $email
      password: $password
    ){
      authenticatable {
        email
      }
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
  }
`;

export default {
  data() {
    return {
      email: '',
      password: '',
      submitStatus: null
    }
  },
  components: {
    DataLoader,
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
  },
  computed: {
    isSubmitStatusPending() {
      return this.isSubmitStatus('pending');
    },
    isSubmitStatusError() {
      return this.isSubmitStatus('error');
    },
    isSubmitStatusSuccess() {
      return this.isSubmitStatus('success');
    },
    formClass() {
      return [
        'auth-form',
        {
          'pending': this.isSubmitStatusPending
        }
      ]
    }
  },
  methods: {
    isSubmitStatus(checkStatus) {
      return this.submitStatus === checkStatus;
    },
    submitForm() {
      if (!this.$v.$invalid) {
        this.submitStatus = 'pending';
        this.sendRequest();
      }
    },
    sendRequest() {
      this.submitStatus = 'pending';
      this.$apollo.mutate({
        mutation: QUERY_MUTATION,
        variables: {
          email: this.email,
          password: this.password,
        },
      })
      .then((data) => {
        auth.applyCredentials(data.data.userLogin.credentials);
        auth.dumpCredentials();

        appContext.init(() => {
          this.submitStatus = auth.errorIsVisible ? 'error' : 'success';

          if (this.submitStatus == 'success') {
            this.$router.push({
              name: 'Portfolio'
            });
          }
        });
      })
      .catch((error) => {
        this.submitStatus = 'error';
        return error;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.auth-form-wrap {
  height: 100vh;
  background: linear-gradient(135deg, rgba($primary, .6), $white);
  padding: 4rem 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-group {
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
}

.auth-form {
  width: 42rem;
  max-width: 90vw;
  text-align: center;
  background-color: #fff;
  border-radius: $border-radius;
  padding: 6rem 4rem;
  position: relative;

  &.pending {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  &:after {
    transition: .3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, .6);
  }
}

h2 {
  color: darken($primary, 8%);
  font-size: 2.4rem;
  margin-bottom: 2.2rem;
}

.svg-icon {
  width: 2rem;
  height: 2rem;
}

.btn {
  min-width: 10rem;
}
</style>
